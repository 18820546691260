<template>
  <div class="maixiangyi">
    <div class="wrapper">
      <div>
        <img src="~assets/img/products/1.png" style="margin-top: 50px" alt="" />
      </div>
      <div>
        <img src="~assets/img/products/2.png" style="margin-top: 50px" alt="" />
      </div>
      <div>
        <img src="~assets/img/products/3.png" style="margin-top: 30px" alt="" />
      </div>
      <div>
        <img src="~assets/img/products/4.png" style="margin-top: 30px" alt="" />
      </div>
      <div>
        <img src="~assets/img/products/5.png" style="margin-top: 30px" alt="" />
      </div>
      <div>
        <img
          src="~assets/img/products/6.png"
          style="margin-top: 30px; margin-bottom: 50px"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maixiangyi'
}
</script>

<style scoped>
.maixiangyi {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>
